import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1024 1024">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
					<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M562.481262,423.441406 
	C555.248962,411.091980 548.880798,398.677979 541.566833,386.782318 
	C535.837280,377.463715 530.500854,367.869873 525.583801,358.099335 
	C523.105835,353.175446 518.845947,349.511169 517.255249,343.318695 
	C491.080811,385.161224 469.162231,428.279724 442.548523,469.048981 
	C455.759918,468.890808 468.486298,470.294708 481.162750,468.544342 
	C483.847015,468.173706 485.346313,465.796021 486.582184,463.663696 
	C492.415619,453.598877 498.101532,443.448608 503.848267,433.333496 
	C505.815063,429.871674 507.598999,426.282806 509.831024,422.999847 
	C513.901733,417.012573 520.921082,417.426056 524.151062,423.963806 
	C529.399353,434.586853 536.205017,444.279755 541.948547,454.586823 
	C546.353333,462.491516 544.351990,470.271820 539.868958,477.736237 
	C533.205627,488.830933 526.590820,499.964203 520.344360,511.295502 
	C517.593628,516.285400 514.059143,518.412598 508.362518,518.383606 
	C480.531250,518.242126 452.679260,517.742188 424.873474,518.612488 
	C406.590881,519.184814 388.245483,516.573547 370.005005,519.819946 
	C366.383820,520.464478 361.615356,520.845581 359.766907,516.671631 
	C357.991608,512.662720 358.980621,508.489288 361.638458,504.465179 
	C375.194366,483.940613 388.257965,463.139526 400.140503,441.542816 
	C410.937683,421.918732 421.559357,402.149780 434.159607,383.508820 
	C441.123260,373.206757 446.354401,361.739258 452.464691,350.852203 
	C455.306946,345.788025 458.381256,340.854065 461.353455,335.862885 
	C463.383759,332.453400 466.419769,331.487610 470.263885,331.765808 
	C482.465424,332.648926 494.494110,330.014496 506.647644,329.766663 
	C507.077515,329.757904 507.499847,329.378815 508.509521,328.889221 
	C504.098053,321.558411 499.730499,314.381775 495.447113,307.155243 
	C492.363556,301.952972 488.170441,297.364868 488.697052,290.461761 
	C489.114227,284.992615 491.439972,280.527100 494.308044,276.296112 
	C499.453430,268.705627 503.503723,260.502594 508.142059,252.632553 
	C509.984344,249.506638 511.801331,246.937149 516.020020,246.691666 
	C520.246155,246.445755 522.333618,248.444107 524.223877,251.798965 
	C532.873840,267.150970 541.310791,282.642731 550.544006,297.641235 
	C561.698120,315.760132 571.588623,334.593414 582.623840,352.775635 
	C591.421448,367.270996 599.615906,382.132141 608.093689,396.821930 
	C615.235107,409.195984 622.086487,421.751984 629.643311,433.867462 
	C636.784546,445.316528 642.019897,457.817169 649.371094,469.080597 
	C657.024109,480.806488 663.296143,493.296356 670.538513,505.235779 
	C671.505493,506.829834 672.220459,508.412354 672.338806,510.333405 
	C672.801392,517.838562 672.521606,518.252930 664.874390,518.274353 
	C651.375183,518.312134 637.874817,518.161804 624.376648,518.280945 
	C619.251831,518.326233 616.093079,516.026062 613.613098,511.682770 
	C603.883118,494.642303 593.897217,477.748199 584.101807,460.744720 
	C577.481262,449.252411 571.028015,437.663818 564.472900,426.133667 
	C563.985229,425.275940 563.279419,424.542175 562.481262,423.441406 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M472.259216,573.495972 
	C472.244049,589.274475 472.260498,604.606018 472.174286,619.937073 
	C472.161285,622.247803 473.166351,625.757080 469.516083,625.885925 
	C465.732666,626.019409 461.118134,628.352539 458.389801,623.186096 
	C453.257416,613.467102 448.012634,603.807068 442.751831,594.156738 
	C442.006378,592.789307 441.641815,591.052490 439.345551,590.044678 
	C439.345551,600.481018 439.145935,610.579590 439.443207,620.663574 
	C439.563080,624.730530 438.562927,626.350403 434.269379,626.313782 
	C430.264496,626.279663 428.637573,625.192566 428.696899,620.904236 
	C428.906586,605.742188 428.886749,590.574158 428.705505,575.411316 
	C428.655731,571.247253 429.746033,569.736084 434.255005,569.398743 
	C440.024261,568.967102 442.870392,571.006409 445.283508,576.102356 
	C449.972046,586.003540 455.435883,595.537598 461.739746,605.090637 
	C461.739746,600.448059 461.739929,595.805481 461.739655,591.162903 
	C461.739319,585.496887 461.725708,579.830872 461.744568,574.164917 
	C461.751740,572.011658 461.467194,569.158997 464.635742,569.534790 
	C467.364471,569.858337 471.928711,567.233337 472.259216,573.495972 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M390.382324,623.620361 
	C371.123749,633.190063 351.096985,620.956665 350.541382,599.869324 
	C350.314697,591.266052 351.524139,583.281860 357.506104,576.661011 
	C365.230865,568.111328 375.058594,566.923889 385.380585,569.788635 
	C396.131287,572.772339 401.940552,580.827515 403.129028,591.579895 
	C403.978760,599.266907 404.091492,607.131287 399.709167,614.332458 
	C397.367645,618.180115 394.346405,621.026428 390.382324,623.620361 
M380.493011,578.790527 
	C372.157562,577.350281 366.159241,580.678772 363.021576,588.311584 
	C360.010925,595.635498 360.913422,602.752991 364.447693,609.490051 
	C368.625092,617.452942 380.129028,619.351501 386.884735,613.480774 
	C396.186157,605.397644 394.831665,582.802368 380.493011,578.790527 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M706.851074,626.166992 
	C699.095215,626.158020 699.342773,626.161621 699.445435,619.045959 
	C699.654480,604.557007 699.568970,590.063782 699.603455,575.572327 
	C699.607727,573.786621 699.604065,572.000977 699.604065,570.611816 
	C701.552673,569.314148 703.098022,569.788208 704.551392,569.599792 
	C708.943848,569.030212 710.562805,570.918640 710.262146,575.277588 
	C709.976257,579.422119 710.287537,583.604187 710.174438,587.765381 
	C710.093201,590.755493 711.387695,591.990784 714.340027,591.929993 
	C719.002136,591.834106 723.669495,591.807129 728.330566,591.920105 
	C731.503174,591.997009 732.866150,590.744568 732.748291,587.491577 
	C732.585632,583.000061 732.893127,578.488953 732.666016,574.003052 
	C732.401550,568.782471 736.020691,569.878906 739.058777,569.583191 
	C742.332642,569.264526 743.499939,570.521667 743.481873,573.776184 
	C743.392151,589.932556 743.350586,606.090759 743.515991,622.246033 
	C743.560120,626.555908 740.802856,626.057739 738.179626,626.150879 
	C735.449463,626.247925 732.633545,626.508118 732.692444,622.373840 
	C732.768311,617.044800 732.567505,611.710022 732.720825,606.384460 
	C732.814758,603.120056 731.465393,601.886597 728.293823,601.971436 
	C723.799622,602.091614 719.296326,602.101929 714.803345,601.958191 
	C711.325806,601.846924 710.058472,603.403625 710.149231,606.761292 
	C710.284180,611.754700 710.152893,616.754761 710.178772,621.751770 
	C710.189819,623.885071 710.030396,625.834473 706.851074,626.166992 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M507.180176,617.084045 
	C506.529694,618.849548 505.967926,620.237854 505.426147,621.633789 
	C503.353912,626.973206 502.500458,627.233032 497.422089,624.960144 
	C494.612427,623.702637 493.647614,622.078735 494.857635,619.133057 
	C501.175140,603.754089 507.484314,588.371216 513.682800,572.944092 
	C515.215454,569.129456 518.276978,569.179688 521.497986,569.143250 
	C524.465759,569.109680 526.308838,570.144287 527.503601,573.189148 
	C533.454590,588.354736 539.587585,603.450500 545.815186,618.505188 
	C547.502014,622.582825 545.788879,624.115051 542.204102,625.436890 
	C538.885132,626.660645 536.949646,626.966309 535.429565,622.821960 
	C531.226135,611.361633 531.020081,611.407104 518.346802,611.402161 
	C513.851257,611.400391 508.581635,609.975403 507.180176,617.084045 
M524.466309,594.914612 
	C523.151489,591.700134 522.462341,588.171021 519.981812,584.815308 
	C517.444153,590.626892 514.998474,595.587036 513.843262,601.363220 
	C518.248108,601.756775 522.107544,602.228516 525.890015,601.120056 
	C526.380310,598.887695 525.338257,597.306396 524.466309,594.914612 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M293.713135,599.000000 
	C293.716980,590.346863 293.866028,582.190063 293.664764,574.041992 
	C293.562408,569.897339 295.837189,569.555603 299.034912,569.567566 
	C302.267365,569.579712 305.120209,569.531555 304.975555,574.043640 
	C304.823608,578.784119 304.941559,583.533264 304.941559,589.812561 
	C311.182434,583.245728 316.503876,578.045105 321.332275,572.421204 
	C324.394318,568.854736 326.480011,569.783813 329.561127,572.322021 
	C333.237335,575.350464 331.408722,577.325989 328.995148,579.493042 
	C323.177856,584.716003 317.326263,589.900818 311.669067,594.940186 
	C319.266846,602.821289 325.409790,609.279175 331.652924,615.638733 
	C335.740417,619.802368 335.176361,623.437866 329.919098,626.371826 
	C327.657654,627.633911 326.476990,625.986450 325.234955,624.774170 
	C319.997192,619.661804 314.851562,614.455139 309.636627,609.319031 
	C308.499603,608.199219 307.639923,606.642700 304.944916,606.348694 
	C304.944916,610.714844 304.974396,614.951050 304.938049,619.186768 
	C304.882568,625.652649 304.348206,625.949829 298.875031,626.260193 
	C293.347900,626.573547 293.676361,623.123230 293.709412,619.466675 
	C293.769531,612.811646 293.718201,606.155640 293.713135,599.000000 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M225.721161,605.807312 
	C230.096878,594.967590 234.504547,584.551208 238.516144,573.984497 
	C239.825531,570.535461 241.772507,569.496155 245.225174,569.555908 
	C248.498398,569.612610 251.162582,569.437378 252.707474,573.411499 
	C258.669312,588.747742 264.935944,603.966614 271.190948,619.187317 
	C272.961945,623.496765 269.975861,624.164124 267.131561,625.425293 
	C263.991364,626.817566 262.027344,626.825928 260.703339,622.995544 
	C256.046783,609.523865 257.563507,611.464539 244.969193,611.404114 
	C233.252075,611.347900 234.999313,609.665588 230.395218,621.841980 
	C228.716293,626.282227 226.566330,626.979797 222.535980,624.879517 
	C219.806961,623.457397 218.827972,622.160461 220.317017,619.092896 
	C222.344666,614.915588 223.862595,610.490845 225.721161,605.807312 
M238.949493,599.846924 
	C242.793472,604.010254 247.110214,600.858337 251.336655,601.415771 
	C250.642685,596.004211 247.933228,589.005737 244.904129,584.760498 
	C242.890869,589.783997 241.010712,594.475342 238.949493,599.846924 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M578.213135,584.011047 
	C578.226562,591.164673 578.113281,597.824036 578.286072,604.476013 
	C578.476562,611.807373 581.931213,616.224915 587.506287,616.724304 
	C594.840088,617.381287 599.497803,613.051575 599.651489,604.932922 
	C599.843567,594.785828 599.830322,584.630249 599.647217,574.483032 
	C599.578064,570.651978 600.631592,569.160339 604.753052,569.367126 
	C608.356567,569.547913 610.630066,569.584106 610.464294,574.314331 
	C610.102966,584.619263 610.476196,594.947754 610.261047,605.260620 
	C610.082092,613.837952 606.681946,620.942566 598.702393,624.802979 
	C590.796936,628.627502 582.918640,627.945740 575.530090,622.972778 
	C570.892395,619.851440 568.526123,615.135864 568.042664,609.950317 
	C566.809387,596.719849 566.658325,583.434204 567.498474,570.378906 
	C568.147034,569.993347 568.422607,569.689697 568.708313,569.679871 
	C579.131531,569.321472 578.157532,568.070190 578.212219,578.521301 
	C578.220947,580.185303 578.212891,581.849487 578.213135,584.011047 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M790.112549,579.260986 
	C782.197388,579.272644 780.461426,581.599670 782.100525,588.949219 
	C782.648987,591.408691 784.285034,591.578369 786.186890,591.591125 
	C790.348877,591.619019 794.513794,591.715698 798.672302,591.598633 
	C801.681030,591.513916 802.939941,592.851074 802.845947,595.764771 
	C802.756836,598.529785 802.799622,601.287415 798.827881,601.231812 
	C794.833008,601.175903 790.832764,601.343994 786.842163,601.203857 
	C783.554810,601.088501 782.063354,602.168579 781.981750,605.746521 
	C781.751099,615.855896 781.617371,615.849976 791.757812,615.847229 
	C794.921143,615.846375 798.092041,616.007751 801.245911,615.839783 
	C805.256409,615.626221 806.342346,617.531555 806.355469,621.279419 
	C806.368774,625.078796 804.764771,626.235901 801.258667,626.193359 
	C792.602356,626.088196 783.942993,626.090515 775.286804,626.203186 
	C772.078125,626.244934 770.786865,624.924866 770.797729,621.764099 
	C770.852600,605.781067 770.851929,589.797668 770.802124,573.814575 
	C770.793091,570.914856 771.880127,569.568604 774.909729,569.589172 
	C784.232544,569.652466 793.557068,569.632324 802.879272,569.512207 
	C806.039612,569.471558 806.416931,571.323669 806.260254,573.720581 
	C806.107178,576.061584 807.050354,579.138123 803.078430,579.216919 
	C798.917725,579.299622 794.754517,579.252808 790.112549,579.260986 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M673.096802,614.795349 
	C676.575684,622.521912 675.511536,624.405334 667.313293,626.168213 
	C641.482727,631.722412 631.423767,610.117981 634.175720,592.563232 
	C635.943359,581.287170 641.081116,572.330688 653.091614,569.347961 
	C659.530823,567.748901 665.937927,568.271301 672.222046,570.628601 
	C675.678284,571.925171 674.570435,574.025757 673.640991,576.234314 
	C672.744751,578.363892 672.884521,581.682007 668.951599,580.398438 
	C668.793396,580.346741 668.642517,580.271240 668.482910,580.225220 
	C657.558838,577.070190 651.690002,578.651733 647.633728,585.841675 
	C642.929260,594.180481 644.653564,607.892578 651.285034,613.661255 
	C655.952515,617.721497 661.310059,617.390503 666.840393,615.718628 
	C668.790222,615.129150 670.480164,613.015564 673.096802,614.795349 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M520.239624,694.215881 
	C516.162537,694.896790 514.725037,693.465576 514.853455,689.651184 
	C515.099976,682.333435 515.031006,675.003052 514.988953,667.678467 
	C514.966858,663.830078 516.684387,662.339539 520.283508,663.697632 
	C521.867981,664.295654 523.098755,664.092651 524.549072,663.544128 
	C525.612610,663.141968 526.880127,662.786072 527.952454,662.981567 
	C534.114197,664.105164 540.202759,663.875488 546.390808,662.978455 
	C550.347717,662.404907 553.110535,664.805420 554.182739,668.584229 
	C556.359375,676.255310 555.148071,684.124878 555.120422,691.910400 
	C555.111206,694.487671 552.790100,694.216248 550.957458,694.241211 
	C548.629028,694.273010 547.687378,693.024963 547.682129,690.823059 
	C547.671082,686.161865 547.736084,681.497070 547.571533,676.840759 
	C547.477051,674.166138 548.211548,670.542603 544.036255,670.362549 
	C539.632263,670.172607 539.206726,673.615967 539.170776,676.959045 
	C539.124207,681.286926 539.140930,685.616150 539.188293,689.944153 
	C539.218445,692.693604 538.362000,694.348816 535.188477,694.315491 
	C532.162354,694.283813 531.615601,692.588562 531.616760,690.124512 
	C531.618896,685.463501 531.614258,680.800354 531.474426,676.142212 
	C531.394348,673.474426 531.397888,670.343628 527.620422,670.323059 
	C523.828247,670.302429 523.174866,673.347961 523.066406,676.282776 
	C522.912903,680.438354 523.111389,684.605713 523.042786,688.766113 
	C523.008362,690.852051 523.449463,693.262878 520.239624,694.215881 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M613.476074,652.643982 
	C615.398376,654.069275 615.011108,655.827698 615.013550,657.446899 
	C615.029419,667.929077 614.997131,678.411316 615.009338,688.893494 
	C615.012634,691.693787 615.074707,694.278198 611.057495,694.332397 
	C607.023865,694.386841 607.005493,691.795105 607.008850,688.997498 
	C607.009644,688.331909 607.016235,687.666260 607.005798,687.000793 
	C606.819092,675.069580 609.075623,676.386292 595.375732,676.345520 
	C592.380493,676.336609 591.225586,677.784668 591.308716,680.677246 
	C591.404236,684.001892 591.273621,687.332458 591.314697,690.659485 
	C591.345337,693.140015 590.294617,694.268433 587.725769,694.274170 
	C585.067810,694.280029 583.683777,693.349792 583.694336,690.526917 
	C583.737305,679.046570 583.742493,667.565857 583.695068,656.085571 
	C583.683105,653.204529 585.388428,652.354614 587.802185,652.201111 
	C590.776794,652.011902 591.419250,653.815430 591.348755,656.336243 
	C591.269775,659.162537 591.440063,661.997864 591.307617,664.820190 
	C591.164490,667.872375 592.559082,668.910400 595.488892,668.898438 
	C609.328125,668.841858 606.912231,669.815125 607.012268,658.054993 
	C607.048828,653.758118 608.108704,651.121277 613.476074,652.643982 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M660.121460,653.734680 
	C665.970154,651.363159 667.345276,652.373779 667.019470,658.078674 
	C666.918213,659.849915 665.857544,661.591187 666.725159,663.405212 
	C667.086121,663.493408 667.415527,663.683533 667.693359,663.625732 
	C673.675293,662.382385 679.505493,662.028259 683.111572,668.239197 
	C686.977661,674.898132 685.850830,685.901917 681.121643,690.714478 
	C677.467407,694.433167 665.984192,695.845093 661.379150,693.232178 
	C660.051208,692.478699 659.479431,691.515747 659.498108,690.034912 
	C659.649109,678.063965 659.778015,666.092834 660.121460,653.734680 
M667.083069,678.679321 
	C667.092834,680.167847 667.061707,681.657898 667.119934,683.144531 
	C667.293823,687.583923 669.594604,688.966614 673.573730,687.053101 
	C678.286865,684.786560 679.341553,673.711975 674.932617,670.995178 
	C672.203369,669.313354 669.357788,669.768433 667.863220,672.951599 
	C667.196838,674.370972 667.318176,676.160095 667.083069,678.679321 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M436.484558,663.097961 
	C444.205353,664.880188 447.592773,670.265747 446.251648,677.603210 
	C445.794708,680.103210 444.272308,680.268799 442.318909,680.347412 
	C437.882477,680.525940 433.392029,679.782898 429.006348,680.851074 
	C429.322723,686.903503 433.305939,689.357178 438.836243,686.849121 
	C441.346863,685.710510 443.299469,684.486511 444.640808,688.199219 
	C445.894501,691.669189 443.577026,692.753540 441.241730,693.704041 
	C434.026642,696.640747 426.182465,694.596313 422.919952,689.070740 
	C418.799072,682.091431 420.194916,669.542053 425.595612,665.364014 
	C428.704987,662.958435 432.342163,662.754395 436.484558,663.097961 
M430.398071,671.450500 
	C431.015411,674.588440 433.338409,675.004639 437.997162,672.707397 
	C436.304199,669.198914 433.651154,669.918579 430.398071,671.450500 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M505.236877,688.914917 
	C500.171021,695.102173 494.006073,696.579163 487.295929,693.529114 
	C482.229980,691.226379 479.072937,683.591370 480.142975,676.230408 
	C481.419373,667.449707 485.294098,663.449951 492.970337,662.989014 
	C501.416748,662.481750 506.931305,667.403931 507.244720,675.933716 
	C507.402435,680.226746 507.747284,684.606018 505.236877,688.914917 
M488.235443,681.068298 
	C489.474243,686.438721 492.233643,688.732239 495.687805,687.262390 
	C498.953247,685.872986 500.625244,680.663086 499.375366,675.861511 
	C498.525238,672.595703 497.261169,669.641296 493.120789,670.310181 
	C488.250183,671.097229 488.284821,675.026001 488.175690,678.744812 
	C488.161102,679.241577 488.186920,679.739441 488.235443,681.068298 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M391.876526,687.406860 
	C393.689575,690.739380 392.332855,692.538452 389.485626,693.382141 
	C378.935669,696.508484 368.331238,694.405212 368.668549,680.057129 
	C368.719360,677.895447 368.546448,675.710938 368.796936,673.574585 
	C369.674164,666.093445 376.052887,661.946777 384.698029,663.108948 
	C391.031677,663.960327 394.941864,669.704407 394.155914,676.686340 
	C393.848022,679.421753 392.543396,680.426575 389.911255,680.341675 
	C386.753082,680.239685 383.588165,680.288208 380.427521,680.346008 
	C378.987335,680.372314 377.137726,679.962463 376.576752,681.809692 
	C375.976990,683.784485 377.115509,685.425049 378.624329,686.550659 
	C381.172241,688.451538 383.935364,688.102356 386.625275,686.771423 
	C388.409576,685.888672 390.170166,684.450256 391.876526,687.406860 
M383.047424,673.981201 
	C384.188629,673.780701 385.544006,673.627686 385.240692,672.031189 
	C384.942413,670.460754 383.510925,670.232605 382.172974,670.185669 
	C379.819031,670.103088 377.672882,670.391968 377.247864,673.351501 
	C379.029724,674.356323 380.693146,673.903076 383.047424,673.981201 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M635.432556,687.273071 
	C640.581604,687.453918 642.611877,684.761169 642.672852,680.250610 
	C642.731201,675.931213 642.789673,671.608582 642.682922,667.291504 
	C642.616516,664.603577 643.674622,663.249695 646.401245,663.187378 
	C649.168030,663.124146 650.229370,664.494690 650.229248,667.169250 
	C650.228882,675.143311 650.227417,683.120117 650.445557,691.089905 
	C650.527161,694.071777 648.700928,695.031189 646.803284,694.018677 
	C644.177856,692.617798 642.211365,693.604614 639.843567,694.193481 
	C630.903687,696.416809 626.651978,693.439758 625.358337,684.260681 
	C625.150818,682.788208 625.116638,681.282898 625.108215,679.792175 
	C625.079163,674.657776 625.096985,669.523132 625.096985,664.598450 
	C632.139465,662.247253 633.086060,662.879700 633.077515,669.295471 
	C633.072021,673.449280 633.044067,677.603516 633.094971,681.756714 
	C633.119446,683.754578 633.187012,685.794312 635.432556,687.273071 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M358.414093,664.091309 
	C358.318237,672.770813 359.162048,681.051575 358.521759,689.360962 
	C358.313538,692.063232 358.518585,694.286865 354.792297,694.338257 
	C350.829254,694.392883 350.706329,692.053955 350.744507,689.114563 
	C350.845825,681.309387 350.827759,673.502380 350.803650,665.696167 
	C350.784241,659.409180 350.702698,659.379700 344.300140,659.408875 
	C341.764740,659.420410 338.598846,660.401611 338.800873,655.948975 
	C338.888428,654.019287 339.584839,652.854248 341.666260,652.619141 
	C350.115356,651.664612 358.587036,652.096924 367.050720,652.155273 
	C369.956818,652.175354 370.450409,654.129456 370.336853,656.604248 
	C370.214355,659.273560 368.843597,659.649292 366.431946,659.789429 
	C363.389832,659.966248 358.463745,657.143799 358.414093,664.091309 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M460.551086,682.143555 
	C462.735626,687.319641 466.040375,689.187927 470.996216,686.648865 
	C473.183380,685.528320 474.450287,686.375488 475.416290,688.268921 
	C476.416168,690.228760 475.911072,691.666504 474.084198,692.939514 
	C467.037903,697.848999 455.872742,693.839539 453.449951,685.580811 
	C452.337677,681.789246 452.281403,677.945068 452.992889,674.221497 
	C455.271759,662.295166 463.577698,661.676514 473.209778,663.823364 
	C475.237396,664.275208 475.931519,665.730225 475.115265,667.620728 
	C474.427704,669.213135 474.070831,671.089905 471.478394,670.981934 
	C461.079620,670.549072 460.089386,671.581848 460.551086,682.143555 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M407.580078,694.779114 
	C404.082306,693.686584 402.642670,691.592590 402.648682,688.322754 
	C402.668549,677.503174 402.664337,666.682800 402.550903,655.864014 
	C402.523529,653.256287 403.679962,652.213440 406.098450,652.200684 
	C408.695618,652.187012 409.423492,653.579834 409.389130,656.039490 
	C409.268127,664.694702 409.294556,673.352478 409.320343,682.009094 
	C409.328888,684.872437 409.033844,688.011047 413.623779,687.411072 
	C415.659576,687.145020 415.987396,688.858948 416.139740,690.401001 
	C416.318542,692.210510 415.844055,693.689087 413.892761,694.340942 
	C411.957581,694.987244 409.991699,695.140625 407.580078,694.779114 
z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
